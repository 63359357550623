<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text">Account access request </strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body" v-if="!isLoading">
            <div class="input-body">
              <div class="row" v-if="isSuccess">
                <div class="col-12">
                  <div class="status-icon success-icon">
                    <i class="fas fa-check"></i>
                  </div>
                </div>
                <div class="col-12">
                  <h3 class="status-title">Submitted!</h3>
                </div>
                <div class="col-12">
                  <p class="status-content">
                    Created <strong> {{successResponse.number}} </strong> - 
                    <a target="_blank" :href="successResponse.url">Click here</a> to track the status of your request.
                  </p>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-12">
                  <div v-if="isFailure" class="alert alert-danger" role="alert">
                    <span>
                      An error has occurred while submitting your request. Please try again. If the problem persists, please contact support.
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <em>To request access for users other than yourself, use the 
                    <a target="_blank" href="https://autodesk.service-now.com/helphub?id=evg_sc_cat_item&sys_id=7699e728dbb46c109d47ec51ca96198b">Corporate AWS Account Access Request</a></em>
                    form.
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label >Account name</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="account.accountCustomName" />
                  </div>
                  <div class="form-group">
                    <label >Account ID</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="account.accountId" />
                  </div>
                  <div class="form-group">
                    <label >Environment</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="account.environment" />
                  </div>
                  <div class="form-group">
                    <label >Requested role * </label>
                    <select class="form-control" v-model="requestedRole">
                      <option v-for="option in availableRolesToRequest" :key="option">{{option}}</option>
                    </select>
                    <em v-if="requestedRoleError" class="required-text">* This is a required field.</em>
                  </div>
                  <div class="form-group">
                    <label >Business justification * </label>
                    <textarea  type="text" class="form-control" v-model="businessJustification">
                    </textarea>
                    <em v-if="businessJustificationError" class="required-text">* Business justification is required.</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else >
            <div class="col-12">
              <p>Submitting access request. This will just take a moment. Please wait...</p>
            </div>
            <div class="col-12">
              <loading-spinner class="p-0" />
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="offset-md-4 col-md-4">
                <button class="modal-default-button btn flat-btn blue-btn" @click="submitRequest()" v-if="!isLoading && !isSuccess" >Submit</button>
              </div>
              <div class="col-md-4">
                <button class="modal-default-button btn flat-btn" @click="close()"  v-if="!isLoading">{{isSuccess?"Close":"Cancel"}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
import util from "@/util";
import * as C from "../../constants.js";
import LoadingSpinner from "../LoadingSpinner.vue";

export default {
  name: "AccessRequestModal",
  props: {
    account: Object
  },
  components: {
    LoadingSpinner
  },
  computed: {
    ...mapGetters([""])
  },
  data: function() {
    return {
      C,
      isLoading: false,
      isFailure: false,
      isSuccess: false,
      requestedRoleError: false,
      businessJustificationError: false,
      requestedRole: "",
      businessJustification: "",
      successResponse: {},
      availableRolesToRequest: []
    };
  },
  mounted() {
    this.computeRolesAvailableToRequest()
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    computeRolesAvailableToRequest(){
      let availableRolesToRequest = ["Owner", "Viewer"].filter( (role)=> !util.parseRole(this.account).split(",").includes(role) )
      this.availableRolesToRequest = availableRolesToRequest
      this.requestedRole = availableRolesToRequest[0]
    },
    async submitRequest() {
      if (util.stringIsBlank(this.requestedRole)) {
        this.requestedRoleError = true
        return
      }
      if (util.stringIsBlank(this.businessJustification)) {
        this.businessJustificationError = true
        return
      }
      this.trackEvent(C.ACTION_TYPES.ACCESS_REQUEST_SUBMIT, 
        this.$router.currentRoute.path+"?view=all-corporate-accounts",  
        `account_id=${this.account.accountId} name=${this.account.accountCustomName}`);

      this.isLoading = true
      let payload = {
        "accountId": this.account.accountId,
        "requestedRole": this.requestedRole,
        "businessJustification":  this.businessJustification
      }
      try {
        let resp = await api.submitAccessRequest(payload);
        
        this.successResponse = resp.data.data
        this.isSuccess = true;
        this.isLoading = false

        this.trackEvent(C.ACTION_TYPES.ACCESS_REQUEST_SUCCESS, 
          this.$router.currentRoute.path+"?view=all-corporate-accounts",  
          `account_id=${this.account.accountId} name=${this.account.accountCustomName}`);
      } catch (err) {
        console.error("Access request error", err)
        this.isFailure = true;
        this.isLoading = false
        
        this.trackEvent(C.ACTION_TYPES.ACCESS_REQUEST_FAILURE, 
          this.$router.currentRoute.path+"?view=all-corporate-accounts",  
          `account_id=${this.account.accountId} name=${this.account.accountCustomName}`);
      }
    }
  }
};
</script>

<style scoped>
.required-text {
  width: 100%;
  margin-top: .25rem;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
  font-size: 80%;
  color: #dc3545;
}
.form-control {
  border-radius: 4px;
}
.modal-wrapper {
  text-align: center;
  line-height: 18px;
}
.form-group {
  text-align: left;
}
.status-icon {
  font-size: 50px;
  margin-bottom:20px;
}
.status-title {
  color: #575757;
  font-size: 26px;
}
.status-content {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}
.success-icon {
  color: #7ed321;
}
.failure-text {
  color: #ed3131;
  font-size: 14px;
  margin-top:5px;
  margin-bottom:5px;
}
.status-icon svg {
  margin: auto;
  display: block;
}
.btn {
  /*height: 43px;*/
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.close-btn {
  font-size: 18px;
  color: white;
}

.close-btn:hover {
  color: white;
}

.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background:  rgba(0, 0, 0, 0.9);
}
.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}

.modal-body {
  padding: 0px 30px;
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}

.modal-body p {
  margin-bottom: 5px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}

.modal-footer {
  margin-top: 20px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}
.modal-default-button {
  float: right;
  padding: 10px 30px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .modal-container {
    width: 100%;
  }
}
</style>